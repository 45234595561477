import request from '@/utils/request'

// 绑卡
export function bankBind(params) {
    return request({
        url: '/v1/mall/bank/bind',
        method: 'post',
        data: params
    })
}

// 绑卡确认
export function confirmBind(params) {
    return request({
        url: '/v1/mall/bank/bind/confirm',
        method: 'post',
        data: params
    })
}

// 绑卡列表
export function getListBind() {
    return request({
        url: '/v1/mall/bank/list',
        method: 'get',
    })
}

// 解除绑卡
export function bankunBind(params) {
    return request({
        url: '/v1/mall/bank/unbind',
        method: 'post',
        data: params
    })
}

// 支付确认
export function confirmPay(params) {
    return request({
        url: '/v1/mall/bank/pay/confirm',
        method: 'post',
        data: params
    })
}


