<template>
  <div class="code_box">
    <div class="login-top">
        <!-- <van-icon name="cross" size="25" @click="doClose"/> -->
    </div>
    <div class="title">
        <div class="text1">请输入验证码</div>
    </div>
    <div class="password_yz">
        <van-password-input
            :value="value"
            :mask="false"
            :focused="showKeyboard"
            :error-info="errorInfo"
            @focus="showKeyboard = true"
            />
    </div>
    <div class="code_tit" v-if="isSendSms">
        {{time}}秒后可重新获取
    </div>
    <div class="code_tit_red" v-else @click="getbankSmS">
        点击重新获取
    </div>
    <!-- 数字键盘 -->
    <van-number-keyboard
    v-model="value"
    :show="showKeyboard"
    @blur="showKeyboard = false"
    />
  </div>
</template>

<script>
import { payOrderConfirm, orderPay } from '@/api/order' // 支付确认 再次发送短信
export default {
  name: "codeBind",
  props: {
     obj: {
        type: Object,
        required: true
     },
  },
  data() {
    return {
        showKeyboard: false,
        value: '',
        phone: '',
        time: '60',
        timerhs: null,
        isSendSms: true,
        params: {
            bankId: 0,
            smsCode: '',
            orderId: 0,
        },
        errorInfo: '',
    };
  },
   watch: {
    value(value) {
      if (value.length === 6) {
        var that = this
        that.params.smsCode = value
        // 确认支付
        payOrderConfirm(that.params).then(res => {
            if (res.state == 200) {
                that.$layer.quicktips('支付成功')
                that.errorInfo = ''
                // 全部关闭
                that.$parent.isBindBuy = false
                that.$parent.isBuyNew = false
            } else {
                that.errorInfo = '请输入正确的短信验证码'
            }
        })
      }
    },
  },
  created() {
    var that = this
    that.params.bankId = that.obj.bindId
    that.params.orderId = that.obj.orderId
    that.timerhs = setInterval(() => {
        that.time --
        if (that.time == 1) {
            clearInterval(that.timerhs)
            that.isSendSms = false
            that.time = 60
        }
    }, 1000)
  },

  methods: {
    // doClose() {
    //     this.$parent.codeBindShow = false
    // },
    // 再次发送短信
    getbankSmS() {
        var that = this
        var payData = {
            payWay: 1, // 3-支付宝 1-银行卡
            orderId: that.params.orderId,
            bindId: that.params.bankId
        }
        orderPay(payData).then(res => {
            if(res.state == 200) {
                that.isSendSms = true
                that.timerhs = setInterval(() => {
                    that.time --
                    if (that.time == 1) {
                        clearInterval(that.timerhs)
                        that.isSendSms = false
                        that.time = 60
                    }
                }, 1000)
            }
        })
    },
  },
};
</script>

<style lang="less" scoped>

.code_box{
    width: 100vw;
    height: 100vh;
    background: #fff;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 1000;
}
.login-top{
    display: flex;
    justify-content: space-between;
    padding: 15rem 17rem;
}
.title{
    padding: 30rem 32rem;
    .text1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24rem;
        color: #000000;
    }
    .text2{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14rem;
        color: #BEC2D1;
        margin-top: 2rem;
    }
}
.password_yz{
    margin-top: 30rem;
}
.code_tit{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14rem;
    color: #BEC2D1;
    margin: 14rem 0rem 0rem 14rem;
}
.code_tit_red{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14rem;
    color: red;
    margin: 14rem 0rem 0rem 14rem;
}
</style>
