<template>
  <div class="goodsinfo">
    <div class="good_header">
        <div class="goodimg">
            <img class="goblack" src="../../../../src/assets/image/black.png" alt="" @click="goblack">
            <van-swipe class="my-swipe" :autoplay="10000" indicator-color="white" :show-indicators="false" @change="onChange">
                <van-swipe-item v-for="(item, index) in goodInfo.pics" :key="index"><img class="imageurl" :src="item" alt=""></van-swipe-item>
            </van-swipe>
            <div class="indicator">{{ current + 1 }}/{{goodInfo.pics.length}}</div>
        </div>
        <div class="gooddata">
            <div class="jiamu">
                <div class="price">￥{{goodInfo.price}}<span class="j_price" v-if="goodInfo.originPrice">￥{{goodInfo.originPrice}}</span></div>
                <div class="stock">已售{{goodInfo.sales}}件</div>
            </div>
            <div class="tigs"  v-if="goodInfo.originPrice">
                <span>限时低价</span>
            </div>
            <div class="title">
                {{goodInfo.name}}
            </div>
        </div>
    </div>
    <div class="good_kd">
        <div class="kd">
            <span>快递</span>
            <tt>预计24小时内发货</tt>
        </div>
        <div class="bz">
            <span>保障</span>
            <tt>官方正品 / 假一赔十 / 逐件查验</tt>
        </div>
    </div>
    <!-- 详情 -->
    <div class="good_xq">
        <div class="xq_tit">
            <span>宝贝详情</span>
        </div>
        <div v-html="goodInfo.intro">

        </div>
        <bottomList></bottomList>
    </div>
    <!-- 底部 -->
    <div class="goodcz">
        <div class="flex1" @click="doCollect(1)" v-if="goodInfo.collectState == 0">
            <div class="icondiv">
                <img src="../../../../src/assets/image/shouc.png">
            </div>
            <div class="text">
                收藏
            </div>
        </div>
        <div class="flex1" @click="doCollect(0)" v-if="goodInfo.collectState == 1">
            <div class="icondiv">
                <img src="../../../../src/assets/image/shouc1.png">
            </div>
            <div class="text">
                已收藏
            </div>
        </div>
        <div class="flex1" @click="goCart">
            <div class="icondiv">
                <van-badge :content="goodInfo.cartNumber" color="#E2BFB0">
                    <img src="../../../../src/assets/image/shoping.png">
                </van-badge>
            </div>
            <div class="text">
                购物车
            </div>
        </div>
        <div class="addshop" @click="isclose = true">加入购物车</div>
        <div class="buy" @click="goPay">立即购买</div>
    </div>
    <!-- isBuyNew = true -->
    <shoppingCart  v-if="isclose"/>
    <buyNow v-if="isBuyNew" ref="buy" />
    <addBind v-if="addBindShow"/>
    <codeBind v-if="codeBindShow" :obj="codeObj" :id="codeId"/>
    <codeBindBuy :obj="payObj" v-if="isBindBuy" ref="bindbuy"></codeBindBuy>
  </div>
</template>

<script>
import shoppingCart from '@/views/components/business/shoppingCart'
import buyNow from '@/views/components/business/buyNow'
import addBind from '@/views/components/business/addBind'
import codeBind from '@/views/components/business/codeBind'
import codeBindBuy from '@/views/components/business/codeBindBuy'


import { productInfo, doCollect } from '@/api/mallPage'
import { getToken } from '@/utils/auth'
export default {
  name: "goodsdetail",
  components: {
    shoppingCart,
    buyNow,
    addBind,
    codeBind,
    codeBindBuy
  },
  data() {
    return {
        payObj: {},
        codeObj: {},
        codeId: 0,
        addBindShow: false,
        codeBindShow: false,
        goodInfo: {
            pics: []
        }, // 商品详情
        isLogin: false, // 是否登录
        current: 0,
        isclose: false,
        isBuyNew: false,
        isBindBuy: false,
        imagelist: [
            require('../../../../src/assets/image/order1.png'), require('../../../../src/assets/image/order2.png'), require('../../../../src/assets/image/order3.png'), require('../../../../src/assets/image/order4.png')
        ],
        CartNum: 0,
    };
  },

  mounted() {
    if(this.$route.params.id) {
        localStorage.setItem('goodId', this.$route.params.id)
    } else {
        this.$route.params.id = localStorage.getItem('goodId')
    }
    this.getProductInfo()
    // 判断是否登录
    this.isLogins()
  },

  methods: {
    // 刷新 银行卡列表
    refreshListBind() {
        this.$refs.buy.getListBind()
    },
    onChange(index) {
      this.current = index;
    },
    // 跳转到我的购物车
    goCart() {
        if ( this.isLogin) {
            this.$router.push('../shoppings/index')
        } else {
            this.$router.push('../login/index')
        }
    },
    // 获取商品详情
    getProductInfo() {
        productInfo(this.$route.params.id).then(res => {
            this.goodInfo = res.data
        })
    },
    // 判断是否登录
    isLogins() {
        const token = getToken()
        if (!token) {
            this.isLogin = false
        } else {
            this.isLogin = true
        }
    },
    // 商品收藏
    doCollect(status) {
        var that = this
        if (this.isLogin) {
            var data = {
                productId: this.$route.params.id,
                collect: status
            }
            doCollect(data).then(res => {
                that.goodInfo.collectState = status
                status == 1? that.$layer.quicktips('已收藏'):that.$layer.quicktips('已取消')
            })
        } else {
            // 去登录
            this.$router.push('../login/index')
        }
    },
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    // 立即购买
    goPay() {
        if (this.isLogin) {
            this.isBuyNew = true
        } else {
            // 去登录
            this.$router.push('../login/index')
        }
    }
  },
};
</script>

<style lang="less" scoped>
.goodsinfo{
    width: 100vw;
    height: 100vh;
    background: #F6F7FA;
    .good_header{
        background: #FFFFFF;
        padding-bottom: 15rem;
    }
}
.goodimg{
    height: 356rem;
    position: relative;
    .goblack{
        position: fixed;
        top: 21rem;
        left: 14rem;
        width: 17rem;
        height: 17rem;
        z-index: 10;
    }
    .my-swipe{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0rem;
        left: 0rem;
        z-index: 5;
        .imageurl{
            width: 100%;
            height: 100%;
        }
    }
    .indicator{
        width: 36rem;
        height: 21rem;
        line-height: 21rem;
        background: #000000;
        border-radius: 11rem;
        opacity: 0.5;
        text-align: center;
        font-family: DINPro, DINPro;
        font-weight: 500;
        font-size: 13rem;
        color: #FFFFFF;
        position: absolute;
        bottom: 0rem;
        right: 14rem;
        z-index: 8;
    }
}
.gooddata{
    margin-top: 12rem;
    .jiamu{
        display: flex;
        margin: 3rem 16rem;
        margin-top: 6rem;
        justify-content: space-between;
        align-items: center;
        .price{
            font-family: DINPro, DINPro;
            font-weight: bold;
            font-size: 19rem;
            color: #000000;
          .j_price{
            font-family: DINPro, DINPro;
            font-weight: 400;
            font-size: 15rem;
            color: #C5C8D3;
            line-height: 17rem;
            margin-left: 4rem;
            position: relative;
            &::after {
              position: absolute;
              left: 4rem;
              top: 10rem;
              content: '';
              width: 52rem;
              height: 1rem;
              background: #C5C8D3;
            }
          }
        }
        .stock{
            font-family: PingFangSC, PingFang SC;
            font-weight: 300;
            font-size: 14rem;
            color: #939297
        }
    }
    .tigs{
        margin: 0rem 16rem;
        margin-top: 7rem;
        span{
            padding: 2rem 6rem;
            background: rgba(187,21,21,0.1);
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #FF3C3C;
        }
    }
    .title{
        margin: 0rem 16rem;
        margin-top: 10rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16rem;
        line-height: 21rem;
        color: #333333;
    }
}
.good_kd{
    margin-top: 10rem;
    background: #fff;
    .kd, .bz{
       margin: 0rem 16rem;
       height: 54rem;
       line-height: 54rem;
       border-bottom: 1rem solid #F6F7FA;
       span{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15rem;
            color: #333333;
       }
       tt{
        margin-left: 12rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 300;
        font-size: 15rem;
        color: #333333;
       }
    }
    .bz{
        border-bottom: none;
    }
}
.good_xq{
    margin-top: 10rem;
    background: #fff;
    padding-bottom: 60rem;
    .xq_tit{
        font-family: PingFangSC, PingFang SC;
        font-weight: 300;
        font-size: 15rem;
        color: #333333;
        line-height: 21px;
        text-align: center;
        padding: 18rem 0rem;
        span{
            position: relative;
            &::after {
              position: absolute;
              left: -50rem;
              top: 8rem;
              content: '';
              width: 40rem;
              height: 1rem;
              background: #C5C8D3;
            }
            &::before {
              position: absolute;
              right: -50rem;
              top: 8rem;
              content: '';
              width: 40rem;
              height: 1rem;
              background: #C5C8D3;
            }
        }
    }
}
.goodcz{
    height: 61rem;
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    display: flex;
    justify-content: space-between;
    padding: 0rem 8rem;
    background: #fff;
    width: calc(100vw - 16rem);
    align-items: center;
    .flex1{
        padding: 0rem 8rem;
        text-align: center;
        .icondiv{
            img{
            vertical-align: middle;
            }
        }
        .text{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
        }
    }
    .addshop{
        width: 121rem;
        height: 44rem;
        line-height: 44rem;
        text-align: center;
        background: #E0BFB1;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15rem;
        color: #333333;
    }
    .buy{
        width: 121rem;
        height: 44rem;
        line-height: 44rem;
        text-align: center;
        background: #000;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15rem;
        color: #fff;
    }
}
</style>
