<template>
  <div>
     <div class="cart_box">
        <div class="cart_zz" @click="doclose"></div>
        <div class="cart_x">
            <div class="cart_header">
                <div class="left">
                    <div class="leftimg">
                        <img :src="$parent.goodInfo.topPic" alt="" srcset="">
                    </div>
                    <div class="leftp">
                       <div class="num">￥{{$parent.goodInfo.price}}</div>
                       <div class="text">请选择“{{typeText}}”</div>
                    </div>
                </div>
                <div class="right" @click="doclose">
                    <van-icon size="25" name="cross" />
                </div>
            </div>
            <div class="saix" v-for="(item, index) in skuAttr" :key="index">
                <div class="title">{{item.name}}</div>
                <div class="saixxx">
                    <span @click="doSelect(index, indexval)" v-for="(itemval, indexval) in item.val" :key="indexval" :class="{'gaol': itemval.isflag}">{{itemval.name}}</span>
                </div>
            </div>
            <div class="num_shop">
                <div class="title">数量</div>
                <div class="stepper"><van-stepper v-model="params.number" /></div>
            </div>
            <div class="shop_but" @click="addCarts">
                加入购物车
            </div>
        </div>
     </div>
  </div>
</template>

<script>
import { addCart } from '@/api/mallPage'
export default {
  name: "shoppingCart",

  data() {
    return {
        value: 1,
        typeText: '',
        skuAttr: [],
        params: {
            skuId: 0,
            number: 1
        }
    };
  },

  mounted() {
    var that = this
    this.$parent.goodInfo.skuAttr.forEach((element, index) => {
        if (index == 0) {
            this.typeText = this.typeText + element.name
        } else {
            this.typeText = this.typeText + ', ' + element.name
        }
        that.skuAttr.push({
            name: element.name,
            val: []
        })
        element.val.forEach((item, i) => {
            if (i == 0) {
                that.skuAttr[index].val.push({
                    isflag: true,
                    name: item
                })
            } else {
                that.skuAttr[index].val.push({
                    isflag: false,
                    name: item
                })
            }
        })
    })
  },

  methods: {
    doclose() {
        this.$parent.isclose = false
    },
    // 切换sku
    doSelect(index, indexval) {
        var that = this
        that.skuAttr.forEach((element, index1) => {
            if (index1 == index) {
                that.skuAttr[index].val.forEach((item, i) => {
                    if (i == indexval) {
                        item.isflag = true
                    } else {
                        item.isflag = false
                    }
                })
            }
        })
    },
    // 加入购物车
    addCarts() {
        var that = this
        if (that.$parent.isLogin) {
            var arr = []
            that.skuAttr.forEach((element, index1) => {
                element.val.forEach((item, i) => {
                    if (item.isflag == true) {
                        arr.push(item.name)
                    }
                })
            })
            that.$parent.goodInfo.skus.forEach((item, index) => {
                if (item.attrs == JSON.stringify(arr)) {
                    that.params.skuId = item.id
                }
            })
            addCart(that.params).then(res => {
                if (res.state == 200) {
                    that.$parent.isclose = false
                    that.$layer.quicktips('已加入购物车')
                    that.$parent.goodInfo.cartNumber += 1
                }
            })
        } else {
            // 去登录
            that.$parent.isclose = false
            that.$router.push('../login/index')
        }
    },
  },
};
</script>

<style lang="less" scoped>
.gaol{
    background: rgba(218,182,167,0.1) !important;
    border: 1px solid #DAB6A7;
    color: #DAB6A7 !important;
}
.cart_box{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 110;
    .cart_zz{
        position: fixed;
        top: 0rem;
        left: 0rem;
        width: 100vw;
        height: 100vh;
        background: #000;
        opacity: 0.6;
        z-index: 111;
    }
    .cart_x{
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        padding: 16rem;
        width: calc(100vw - 32rem);
        height: calc(80% - 32rem);
        background: #fff;
        z-index: 222;
        .cart_header{
            display: flex;
            justify-content: space-between;
            .left{
                display: flex;
                align-items: end;
                .leftimg{
                    img{
                        width: 110rem;
                        height: 110rem;
                    }
                }
                .leftp{
                    margin-left: 10rem;
                    .num{
                        font-family: DIN, DIN;
                        font-weight: bold;
                        font-size: 19rem;
                        color: #333333;
                    }
                    .text{
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 13rem;
                        color: #7E8892;
                    }
                }
            }
        }
        .saix{
            margin-top: 20rem;
            .title{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 15rem;
                color: #333333;
            }
            .saixxx{
                display: flex;
                flex-wrap: wrap;
                span{
                    padding: 10rem 29rem;
                    background: #F6F7FA;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 13rem;
                    color: #939297;
                    margin-top: 10rem;
                    margin-right: 10rem;
                }
            }
        }
        .num_shop{
            margin-top: 20rem;
            display: flex;
            justify-content: space-between;
                
            .title{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 15rem;
                color: #333333;
            }
        }
    }
    .shop_but{
        width: 343rem;
        height: 49rem;
        background: #E0BFB1;
        line-height: 49rem;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        position: absolute;
        bottom: 20rem;
    }
}
</style>
