<template>
  <div class="register_box">
    <div class="black">
        <van-icon @click="doClose" name="cross" size="25"/>
    </div>
    <div class="title">
        <div class="text1">添加银行卡</div>
        <div class="text2">请绑定持卡本人银行卡</div>
    </div>
    <!-- 注册框 -->
    <div class="login_pop">
        <div class="account_div">
            <input class="account" type="text" v-model="params.name" placeholder="持卡人姓名">
        </div>
        <div class="account_div">
            <input class="account" type="number" v-model="params.cardNo" placeholder="卡号">
        </div>
        <div class="account_div">
            <input class="account" type="text" v-model="params.certNo" placeholder="持卡人身份证">
        </div>
        <div class="account_div">
            <input class="account" type="number" v-model="params.mobile" placeholder="银行卡绑定手机号">
        </div>
    </div>
    <div class="last_but">
        <div class="login_but" @click="getbankBind">下一步</div>
    </div>
    <bottomList></bottomList>
  </div>
</template>

<script>
import { bankBind } from '@/api/bind' // 绑卡
export default {
  name: "addBind",

  data() {
    return {
        params: {
            cardNo: "",
            name: "",
            certNo: "",
            mobile: ""
        }
    };
  },

  mounted() {},

  methods: {
    doClose() {
        this.$parent.addBindShow = false
    },
    // 绑卡
    getbankBind() {
        var that = this
        if (!that.params.name) {
            return that.$layer.quicktips('请输入持卡人姓名')
        }
        if (!that.params.cardNo) {
            return that.$layer.quicktips('请输入卡号')
        }
        if (!that.params.certNo) {
            return that.$layer.quicktips('请输入持卡人身份证')
        }
        const reg = /^1[3-9]{1}[0-9]{9}$/
        if (!reg.test(that.params.mobile)) {
            return that.$layer.quicktips('请输入正确的绑定手机号')
        }
        bankBind(that.params).then(res => {
            if(res.state == 200) {
                // 跳转
                that.$parent.codeBindShow = true
                that.$parent.codeObj = that.params
                that.$parent.codeId = res.data
                // that.$router.push({
                //     name: `codeBind`,
                //     params: {
                //         obj: JSON.stringify(that.params),
                //         id: res.data
                //     }
                // })
            }
        })
    }
  },
};
</script>

<style lang="less" scoped>
.register_box{
    width: 100vw;
    height: 100vh;
    background: #fff;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 1000;
}
.black{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    .van-icon{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
}
.title{
    padding: 30rem 32rem;
    .text1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24rem;
        color: #000000;
    }
    .text2{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14rem;
        color: #BEC2D1;
        margin-top: 2rem;
    }
}
.login_pop{
    padding: 30rem 32rem;
    input{
        border: none;
        font-family: DINPro, DINPro;
        font-weight: 500;
        font-size: 16rem;
        color: #333333;
    }
    .account{
        padding: 10rem 0rem;
    }
    .password{
        padding: 10rem 0rem;
    }
}
.account_div{
    border-bottom: 1rem solid #F5F6FB;
    padding: 10rem 0rem;
}
.password_div{
    border-bottom: 1rem solid #F5F6FB;
}
.code_div{
    padding: 20rem 0rem 10rem 0rem;
    border-bottom: 1rem solid #F5F6FB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16rem;
        color: #D1B4A8;
    }
}
.last_but{
    margin-top: 40rem;
    padding: 0rem 33rem;
    .login_but{
        height: 49rem;
        background: #E0BFB1;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
    }
    .dx_but{
        height: 49rem;
        background: #F2F3FC;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
        margin-top: 12rem;
    }
    .checkbox{
        margin-top: 12rem;
        tt{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #8D9399;
        }
        span{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #CBAA9C;
        }
    }
}
</style>
